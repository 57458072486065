var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: "mdi-alert", title: "Não autorizado" } },
    [
      _c(
        "v-container",
        { staticClass: "text-center", attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c("h1", { staticClass: "text-h1 red--text" }, [
                    _vm._v("Ops, 500")
                  ]),
                  _c("p", { staticClass: "text-h5 mt-4" }, [
                    _vm._v("Ocorreu um problema!")
                  ]),
                  _c("p", { staticClass: "body-1" }, [
                    _vm._v(
                      "Para um melhor acompanhamento do problema, envie para "
                    ),
                    _c("strong", [_vm._v("helpdesk@emcsoft.com.br")]),
                    _vm._v(
                      " os passos feitos até receber essa mensagem. Logo entraremos em contato."
                    )
                  ]),
                  _c(
                    "v-btn",
                    { attrs: { to: "/", color: "primary", outlined: "" } },
                    [_vm._v(" Voltar para o início! ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }